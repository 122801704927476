import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
import { Box, Text, Image, Heading, Card } from 'rebass';
import Backgroundfm from '../pages/cato.jpg';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const FeedSearch = makeShortcode("FeedSearch");
const Tags = makeShortcode("Tags");
const FeedItems = makeShortcode("FeedItems");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Box sx={{
      px: 4,
      py: 6,
      backgroundImage: `url(${Backgroundfm})`,
      backgroundSize: 'cover',
      borderRadius: 8,
      color: 'white',
      bg: 'gray'
    }} mdxType="Box">
  <Heading mdxType="Heading">
      <Text fontSize={[20, 24, 35]} textAlign='center' fontWeight='bold' includeFontPadding='no' backgroundColor='#45ada8' color=' #f2f2f2' mdxType="Text">
      Les ruses de Meehan!
      </Text>
  </Heading>
    </Box>
    <hr></hr>
    <h2 {...{
      "id": "bonjour-à-vous-"
    }}>{`Bonjour à vous !`}</h2>
    <p>{`Bienvenu sur Les `}<em parentName="p">{`Ruses de Meehan`}</em><sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{` de Meehan.`}</p>
    <p>{`En ces moments difficiles, je compte vous divertir avec de petites doses de technologie, de photographie et autres sujets.`}</p>
    <p>{`J'ai refait mon Branding personnel`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` , de la palette de couleurs à la typographie et monté ce site Web avec Gatbs JS`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup></p>
    <p>{`Et j'ai même le luxe d'avoir une éditrice! `}<sup parentName="p" {...{
        "id": "fnref-4"
      }}><a parentName="sup" {...{
          "href": "#fn-4",
          "className": "footnote-ref"
        }}>{`4`}</a></sup></p>
    <h2 {...{
      "id": "bonne-visite-"
    }}>{`Bonne visite !`}</h2>
    <p>{`N'hésitez pas à commenter les articles, bonnes lectures et surtout prenez bien soin de vous et de vos proches.`}</p>
    <p>{`On ne lâche pas !`}</p>
    <p><em parentName="p">{`François Meehan`}</em></p>
    <hr></hr>
    <FeedSearch mdxType="FeedSearch" />
    <Tags mdxType="Tags" />
    <hr />
    <FeedItems mdxType="FeedItems" />

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`Un gros Merci à Monique McClemens pour avoir pondu le titre du site, Monique, le chèque est dans la malle`}<a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Merci à Samuel Lampron, graphiste pour son aide `}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`Site de `}<a parentName="li" {...{
            "href": "https://www.gatsbyjs.org/"
          }}>{`Gatsby JS`}</a><a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-4"
        }}>{`Merci également à Valou pour l'édition des textes`}<a parentName="li" {...{
            "href": "#fnref-4",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      